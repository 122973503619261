import axios from "axios"
import Cookies from "js-cookie"
import { DTV_DISCORD_ACCESS_TOKEN, DISCORD_ACCESS_TOKEN_URL, DISCORD_REFRESH_TOKEN_URL } from "../constants/userSession"

const withToken = function (config) {
  // don't intercept auth route
  if (config.url === DISCORD_ACCESS_TOKEN_URL || config.url === DISCORD_REFRESH_TOKEN_URL)
    return config

  const token = Cookies.get(DTV_DISCORD_ACCESS_TOKEN)
  if (token) {
    config.headers["Authorization"] = "Bearer " + token
  }
  return config
}

export const baseApi = axios.create({
  baseURL: process.env.API_URL,
})

// Authenticated routes
baseApi.interceptors.request.use(withToken)
import React from "react"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import MuiLink from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"

import twitter_ico from "../images/twitter_ico.svg"
import youtube_ico from "../images/youtube_ico.svg"
import discord_ico from "../images/discord_ico.svg"
import LogoDTV from "../images/about/logo-dtv.png"

const styles = makeStyles(() => ({
  linkproperty: {
    color: "#FFFFFF",
  },
}))

export default function Footer() {
  const classes = styles()
  return (
    <Box my={5}>
      <Container maxWidth="md">
        <Grid container
          spacing={4}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
            <img src={LogoDTV} height="20px" />
          </Grid>
          <Grid item >
            <Box mt={1}>
              <Typography variant="caption" className={classes.linkproperty}>Copyright© 2020 demtovideo</Typography>
            </Box>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Box mt={0} >
              <Typography variant="caption" className={classes.linkproperty}>Find us on </Typography>
            </Box>
          </Grid>
          <Grid item>
            <MuiLink href="https://www.youtube.com/channel/UC_6hTS5AZeghNy76mhHb7Qg" target="blank"><img src={youtube_ico} /></MuiLink>
            <MuiLink href="https://twitter.com/demtovideo" target="blank"><img src={twitter_ico} /></MuiLink>
            <MuiLink href="https://discord.gg/c28znCS" target="blank"><img src={discord_ico} /></MuiLink>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

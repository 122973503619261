import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useSelector } from "react-redux"

import { Grid, Dialog, CardMedia, Box, IconButton, Typography, Card } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded"

import YouTubePlayer from "../YoutubePlayer"
import ReplayRelated from "./ReplayRelated"
import { MonthNames } from "../../constants/global"
import { matchDialog, checkAliasForTeamRelatedReplay, checkAliasForTeamLogo } from "../../utils/helpers"

const styles = makeStyles(() => ({
  cardSizingRelated: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    borderBottom: "1px groove #FFFFFF",
  },
  colortypoCardTitle: {
    color: "#FFFFFF",
    textShadow: "black 1px 1px 2px",
    fontWeight: "600",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  childrenAppBar: {
    color: "#FFFFFF",
  },
  cardMatchSizing: {
    width: "100%",
    height: "100%",
    borderBottom: "1px groove #FFFFFF",
  },
  teamLogoProperty: {
    height: "50px",
  },
}))

export default function ReplayDialog(props) {
  const classes = styles()

  const esportTeams = useSelector(state => state.esportTeams)

  let teamLogoA = ""
  let teamLogoB = ""

  const [ref, setRef] = useState(null)

  return (
    <Dialog maxWidth="lg" fullWidth scroll="body" open={props.open} onClose={props.onClose}>
      <CardMedia image={matchDialog(props.replay.map)} className={classes.cardMatchSizing}>
        <Box ml={1}>
          <IconButton onClick={props.onClose} edge="start">
            <CloseIcon className={classes.childrenAppBar} />
          </IconButton>
        </Box>
        <Box pt={2} align="center">
          <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
            {props.logoFolder.allFile.edges.forEach(folder => {
              if (folder.node.name + folder.node.ext === checkAliasForTeamLogo(props.replay.team_a, esportTeams)) {
                teamLogoA = folder.node.publicURL
              }
              else if (folder.node.name + folder.node.ext === checkAliasForTeamLogo(props.replay.team_b, esportTeams))
                teamLogoB = folder.node.publicURL
            })}
            <Grid item xs={3}>
              <img src={teamLogoA} className={classes.teamLogoProperty} />
              <Typography variant="h6" noWrap className={classes.colortypoCardTitle}>
                {checkAliasForTeamRelatedReplay(props.replay.team_a, esportTeams)}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h3" className={classes.colortypoCardTitle}>{props.replay.team_a_score ?? "0"}</Typography>
            </Grid>
            <Grid item xs={1}>
              <RemoveRoundedIcon fontSize="large" className={classes.childrenAppBar} />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h3" className={classes.colortypoCardTitle}>{props.replay.team_b_score ?? "0"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <img src={teamLogoB} className={classes.teamLogoProperty} />
              <Typography variant="h6" noWrap className={classes.colortypoCardTitle}>{checkAliasForTeamRelatedReplay(props.replay.team_b, esportTeams)}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box align="center" pb={2} mt={3}>
          <Grid container direction="row" justify="center" alignItems="flex-end" spacing={0}>
            <Grid item xs={12}>
              <Typography variant="body2" noWrap className={classes.colortypoCardTitle}>{props.replay.esport_event.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.colortypoCardTitle}>{props.replay.map}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" noWrap className={classes.colortypoCardTitle}>{MonthNames[props.replayDate.getMonth()]} {props.replayDate.getFullYear()}</Typography>
            </Grid>
          </Grid>
        </Box>
      </CardMedia>
      <Box mt={2} align="center">
        <YouTubePlayer id={props.youtubePlayerID} customRef={ref} setRef={setRef} highlightRound={props.highlightRound} />
      </Box>
      {!props.highlightRound && ref !== null ?
        <Box pb={5} clone>
          <Card className={classes.cardSizingRelated}>
            <ReplayRelated folderTeamA={props.folderTeamA} folderTeamB={props.folderTeamB} replay={props.replay} youtubePlayerRef={ref} currentID={props.youtubePlayerID} />
          </Card>
        </Box>
        : <Box pb={3} />
      }
    </Dialog>
  )
}

import { createMuiTheme } from "@material-ui/core/styles"

export const themeObject = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  MuiButton: {
    background: "primary",
  },
  button: {
    "&:focus": {
      ouline: "none",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "5px",
        backgroundColor: "#1BE483",
        "&:hover": {
          backgroundColor: "#1BE483",
        },
      },
    },
    MuiPaginationItem: {
      root: {
        color: "#FFFFFF",
      },
      textPrimary: {
        color: "#FFFFFF",
      },
    },
    MuiPagination: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#001C28",
      },
      outlined: {
        border: "1px solid #FFFFFF",
      },
      elevation1: {
        boxShadow: 0,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },

    },
  },

}

export default createMuiTheme(themeObject)

import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { compose } from "redux"
import { makeStyles } from "@material-ui/core/styles"

import { AppBar, Box, Button, Container, Grid, TextField, Typography, MenuItem, Card } from "@material-ui/core"
import MuiLink from "@material-ui/core/Link"

import HeaderImage from "../../images/demtovideo-app.png"
import discordImage from "../../images/about/join.png"
import d2Icon from "../../images/map/map_icon_de_dust2.png"
import infernoIcon from "../../images/map/map_icon_de_inferno.png"
import mirageIcon from "../../images/map/map_icon_de_mirage.png"
import nukeIcon from "../../images/map/map_icon_de_nuke.png"
import overpassIcon from "../../images/map/map_icon_de_overpass.png"
import trainIcon from "../../images/map/map_icon_de_train.png"
import vertigoIcon from "../../images/map/map_icon_de_vertigo.png"
import ancienIcon from "../../images/map/map_icon_de_ancient.png"
import sideCT from "../../images/iconSideCt.png"
import sideT from "../../images/iconSideT.png"
import csgologo from "../../images/team/csgologo.png"
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined"
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined"

import Footer from "../Footer"
import Navbar from "../Navbar"
import ReplayList from "../replays/ReplayList"
import FootBar from "../FootBar"

import withLocation from "../../utils/withLocation"
import { fetchDefaultReplays, fetchReplays } from "../../actions/replays"
import { fetchEsportGames } from "../../actions/esportGames"
import { fetchEsportEvents } from "../../actions/esportEvents"
import { fetchEsportTeams } from "../../actions/esportTeams"
import { fetchHighlightRoundList } from "../../actions/highlightRound"
import { doSearch, clearResults } from "../../actions/search"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#001620",
  },
  colorTypo: {
    color: "#F9F9F3",
    fontWeight: "800",
    textShadow: "3px 3px #001C28",
  },
  formControl: {
    minWidth: 120,
    color: "#FFFFFF",
    "& > *": {
      color: "#FFFFFF",
    },
  },
  colorSelect: {
    color: "#FFFFFF",
    "& > *": {
      color: "#FFFFFF",
    },
  },
  midBar: {
    background: "#001C28",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    boxShadow: "none",
    top: "auto",
  },
  buttonProperty: {
    backgroundColor: "#1BE483",
    "&:hover": {
      backgroundColor: "#1BE483",
    },
    fontWeight: "900",
  },
  hrColor: {
    border: 0,
    borderTop: "1px solid",
    borderTopColor: "#001C28",
  },
}))

function Dashboard(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [searchInput, setSearchInput] = useState("")
  const [filter, setFilter] = useState({})

  const replays = useSelector(state => state.replays)
  const highlightRound = useSelector(state => state.highlightRound)
  const esportEvents = useSelector(state => state.esportEvents)
  const esportTeams = useSelector(state => state.esportTeams)
  const search = useSelector(state => state.search)

  useEffect(() => {
    let filter = {}
    if (props.urlParams.map)
      filter.map = props.urlParams.map
    if (props.urlParams.team)
      filter.team = props.urlParams.team
    if (props.urlParams.side)
      filter.side = props.urlParams.side
    if (props.urlParams.esportEventName)
      filter.esportEventName = props.urlParams.esportEventName
    if (props.urlParams.searchInput) {
      setSearchInput(props.urlParams.searchInput)
      searchAndFetch()
    }
    setFilter(filter)
    if (!filter.map || !filter.team || !filter.side || !filter.esportEventName) {
      dispatch(fetchDefaultReplays())
      dispatch(fetchEsportGames())
      dispatch(fetchHighlightRoundList())
    }
    dispatch(fetchEsportEvents())
    dispatch(fetchEsportTeams())
  }, [dispatch])

  useEffect(() => {
    if (filter.map || filter.team || filter.side || filter.esportEventName) {
      searchAndFetch()
      let half
      if (filter.side == "Terrorist")
        half = 2
      if (filter.side == "Counter")
        half = 3
      dispatch(fetchReplays(filter.map, filter.team, half, filter.esportEventName)).then(() =>
        dispatch(fetchEsportGames(filter.map, filter.team, filter.esportEventName)))
    }
  }, [dispatch, filter])

  const filtersToURL = () => {
    let filterSearchParams = new URLSearchParams()
    if (filter.map)
      filterSearchParams.append("map", filter.map)
    if (filter.team)
      filterSearchParams.append("team", filter.team)
    if (filter.side)
      filterSearchParams.append("side", filter.side)
    if (filter.esportEventName)
      filterSearchParams.append("esportEventName", filter.esportEventName)
    if (searchInput !== "")
      filterSearchParams.append("searchInput", searchInput)
    if (Array.from(filterSearchParams).length > 0)
      history.pushState(null, "", props.location.pathname + "?" + filterSearchParams.toString())
    else
      history.pushState(null, "", props.location.pathname)
  }

  const searchAndFetch = () => {
    if (searchInput !== "") {
      const filterFinalValue = JSON.stringify((filter.team ?? "") + " " + (filter.side ?? "") + " " + (filter.esportEventName ?? "") + " " + (filter.map ?? ""))
      dispatch(doSearch(searchInput + " " + filterFinalValue))
    }
    filtersToURL()
  }

  const handleChangeValue = (event) => {
    const { name, value } = event.target
    setFilter({ ...filter, [name]: value })
  }

  const handleResetField = () => {
    setSearchInput("")
    setFilter({})
  }

  const clearSearchFilterAndFields = () => {
    handleResetField()
    dispatch(clearResults())
    history.pushState(null, "", "/app")
  }

  const maps = [
    { value: "de_inferno", icon: infernoIcon, name: "de_inferno" },
    { value: "de_dust2", icon: d2Icon, name: "de_dust2" },
    { value: "de_mirage", icon: mirageIcon, name: "de_mirage" },
    { value: "de_nuke", icon: nukeIcon, name: "de_nuke" },
    { value: "de_train", icon: trainIcon, name: "de_train" },
    { value: "de_overpass", icon: overpassIcon, name: "de_overpass" },
    { value: "de_vertigo", icon: vertigoIcon, name: "de_vertigo" },
    { value: "de_ancient", icon: ancienIcon, name: "de_ancient" },
  ]

  return (
    <Box className={classes.root}>
      <Navbar />
      <FootBar />
      <Box mt={8}>
        <img src={HeaderImage} width="100%" height="100%" />
      </Box>
      <AppBar position="sticky" className={classes.midBar}>
        <Container maxWidth="xl">
          <Grid container spacing={2} alignItems="center" justify="center">
            <Grid item xs="auto">
              <TextField
                select
                variant="outlined"
                size="small"
                value={filter.map ?? ""}
                onChange={(event) => handleChangeValue(event)}
                label="Maps"
                name="map"
                SelectProps={{
                  onClose: () => {
                    setTimeout(() => {
                      document.activeElement.blur()
                    }, 0)
                  },
                }}
                className={classes.formControl}>
                <Box display="flex" justifyContent="center">
                  <MenuItem className={classes.formControl} value="">All Maps</MenuItem>
                </Box>
                {maps.map(item => (
                  <MenuItem key={item.value} value={item.value} className={classes.formControl}>
                    <Box display="flex" justifyContent="center">
                      <img src={item.icon} height="20px" />
                      &nbsp; &middot; &nbsp;{item.name}
                    </Box>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs="auto">
              <TextField
                select
                variant="outlined"
                size="small"
                value={filter.team ?? ""}
                onChange={(event) => handleChangeValue(event)}
                label="Teams"
                name="team"
                SelectProps={{
                  onClose: () => {
                    setTimeout(() => {
                      document.activeElement.blur()
                    }, 0)
                  },
                }}
                className={classes.formControl}>
                <MenuItem className={classes.formControl} value=""><img src={csgologo} height="24px" /> &nbsp; &middot; &nbsp;All Teams</MenuItem>
                {esportTeams.list.map((team) => {
                  let teamLogoURL = ""
                  props.logoFolder.allFile.edges.forEach(folder => {
                    if (folder.node.name + folder.node.ext === team.logo)
                      teamLogoURL = folder.node.publicURL
                  })
                  return (
                    <MenuItem className={classes.formControl} value={team.name} key={team.id}><img src={teamLogoURL} height="24px" />&nbsp; &middot; &nbsp;{team.name}</MenuItem>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item xs="auto">
              <TextField
                select
                variant="outlined"
                size="small"
                value={filter.side ?? ""}
                onChange={(event) => handleChangeValue(event)}
                label="Side"
                name="side"
                SelectProps={{
                  onClose: () => {
                    setTimeout(() => {
                      document.activeElement.blur()
                    }, 0)
                  },
                }}
                style={{ color: "#FFFFFF" }}
                className={classes.formControl}>
                <MenuItem className={classes.formControl} value=""><img src={sideCT} height="20px" />&nbsp; Both &nbsp;<img src={sideT} height="20px" /></MenuItem>
                <MenuItem className={classes.formControl} value="Counter"><img src={sideCT} height="20px" /> &nbsp; &middot; CT</MenuItem>
                <MenuItem className={classes.formControl} value="Terrorist"><img src={sideT} height="20px" /> &nbsp; &middot; T</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs="auto">
              <TextField
                select
                variant="outlined"
                size="small"
                value={filter.esportEventName ?? ""}
                onChange={(event) => handleChangeValue(event)}
                label="Event"
                name="esportEventName"
                SelectProps={{
                  onClose: () => {
                    setTimeout(() => {
                      document.activeElement.blur()
                    }, 0)
                  },
                }}
                className={classes.formControl}>
                {esportEvents.list.map((esportEvent) =>
                  <MenuItem className={classes.formControl} value={esportEvent.name} key={esportEvent.id}>&nbsp; &middot; &nbsp;{esportEvent.name}</MenuItem>,
                )}
              </TextField>
            </Grid>
            <Grid item xs="auto">
              <form onSubmit={e => { e.preventDefault() }}>
                <TextField
                  variant="outlined"
                  size="small"
                  label={<Box mt={-0.3}><SearchOutlinedIcon /> Search</Box>}
                  placeholder="players, teams, maps ..."
                  className={classes.colorSelect}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      searchAndFetch()
                    }
                  }} />
              </form>
            </Grid>
            <Grid item xs="auto">
              <Button onClick={() => clearSearchFilterAndFields()}>
                <b>Clear</b>
                <Box mt={-0.1} ml={0.1}>
                  <ClearOutlinedIcon />
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      {
        search.results !== null ?
          <>
            {search.results.length === 0 ?
              <Container maxWidth="md" align="center">
                <Typography variant="h5" gutterBottom className={classes.colorTypo}>No result found</Typography>
              </Container>
              :
              <ReplayList list={search.results} row={2} logoFolder={props.logoFolder} title="SEARCH RESULTS" />
            }
            <Container maxWidth="md">
              <Box my={10}>
                <hr className={classes.hrColor} />
              </Box>
            </Container>
          </>
          :
          <>
            {replays.list && replays.trendingList && replays.latestList && replays.esportGameList ?
              filter && Object.keys(filter).length === 0 && filter.constructor === Object ?
                <>
                  {replays.trendingList.length > 0 &&
                    <ReplayList list={replays.trendingList} logoFolder={props.logoFolder} row={1} teamsFolder={esportTeams} title="TRENDING" />
                  }
                  <ReplayList list={replays.latestList} logoFolder={props.logoFolder} row={1} teamsFolder={esportTeams} title="LATEST REPLAYS" />
                  <ReplayList list={replays.esportGameList} esportGame logoFolder={props.logoFolder} row={2} teamsFolder={esportTeams} title="LATEST GAMES" />
                  <ReplayList list={highlightRound.list} highlightRound logoFolder={props.logoFolder} teamsFolder={esportTeams} row={1} title="DTV ACE" />
                </>
                :
                <>
                  <ReplayList list={replays.list} logoFolder={props.logoFolder} teamsFolder={esportTeams} row={2} title="SEARCH RESULTS" />
                </>
              : null
            }
            <Container maxWidth="md">
              <Box my={10}>
                <hr className={classes.hrColor} />
              </Box>
            </Container>
          </>
      }
      <Box my={20}>
        <Container maxWidth="md">
          <Grid container>
            <Grid item xs={2}>
              <Box position="absolute" zIndex="modal" mt={-1} ml={4}>
                <Card style={{ background: "#1BE483" }} align="center">
                  <Box px={1}>
                    <Typography variant="subtitle2" style={{ color: "#001C28", fontWeight: "800" }}>COMMUNITY</Typography>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="space-around">
            <Grid item xs="auto">
              <Card>
                <img src={discordImage} style={{ backgroundPosition: "center", width: "100%" }} />
              </Card>
            </Grid>
            <Grid item xs="auto">
              <Card style={{ background: "#001620", boxShadow: "none" }}>
                <Typography variant="h6" className={classes.colorTypo}>
                  WE NEED YOU !
                </Typography>
                <Box my={1} >
                  <MuiLink underline="none" component={Button} href="https://discord.gg/c28znCS" target="blank" size="medium" className={classes.buttonProperty}>Join Discord now</MuiLink>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="md">
        <Box my={10}>
          <hr style={{ border: 0, borderTop: "1px solid", borderTopColor: "#001C28" }} />
        </Box>
      </Container>
      <Footer />
    </Box >
  )
}

export default compose(
  withLocation,
)(Dashboard)

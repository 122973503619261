import {
  GET_ESPORT_TEAM_LIST_SUCCESS,
  GET_ESPORT_TEAM_LIST_FAILED,
} from "../constants/actionTypes"
import { getEsportTeamList } from "../api/esportTeams"

export const fetchEsportTeams = () => dispatch => new Promise((resolve, reject) => {
  try {
    getEsportTeamList().then((response) => {
      dispatch({
        type: GET_ESPORT_TEAM_LIST_SUCCESS,
        payload: { list: response.data.esport_teams },
      })
      resolve()
    })

  } catch (error) {
    dispatch({
      type: GET_ESPORT_TEAM_LIST_FAILED,
      payload: { error },
    })
    reject()
  }
})

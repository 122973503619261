import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"

import { Grid, Card, CardActions, CardActionArea, CardMedia, Typography, Box, GridListTileBar } from "@material-ui/core"
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons"
import { faSkullCrossbones } from "@fortawesome/free-solid-svg-icons"
import sideCT from "../../images/iconSideCt.png"
import sideT from "../../images/iconSideT.png"

import ReplayDialog from "./ReplayDialog"
import { fetchEsportGamesReplay } from "../../actions/esportGames"
import { fetchMetaData } from "../../actions/replayMetaData"
import { MonthNames } from "../../constants/global"
import { matchDialog, checkAliasForTeamRelatedReplay, checkAliasForTeamLogo } from "../../utils/helpers"

const styles = makeStyles(() => ({
  cardSizing: {
    minHeight: "340px",
    maxHeight: "340px",
  },
  colortypoCardTitleName: {
    color: "#1BE483",
    textShadow: "black 1px 1px 2px",
    fontWeight: "600",
    "&:hover": {
      color: "#1BE483",
    },
  },
  colortypoCardTitle: {
    color: "#FFFFFF",
    textShadow: "black 1px 1px 2px",
    fontWeight: "600",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  media: {
    height: "160px",
    borderBottom: "groove #FFFFFF",
  },
  iconProperty: {
    color: "#FFFFFF",
    marginLeft: 5,
  },
  sideIconCounter: {
    backgroundImage: `url(${sideCT})`,
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "15%",
  },
  sideIconTerro: {
    backgroundImage: `url(${sideT})`,
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "15%",
  },
  childrenAppBar: {
    color: "#FFFFFF",
  },
  cardGameSizing: {
    width: "100%",
    height: "220px",
    borderBottom: "1px groove #FFFFFF",
  },
  teamLogoProperty: {
    height: "50px",
  },
}))

export default function ReplayItemWrapper(props) {
  const classes = styles()
  const dispatch = useDispatch()
  const replays = useSelector(state => state.replays)
  const esportTeams = useSelector(state => state.esportTeams)

  const [open, setOpen] = useState(false)
  const [raised, setRaised] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setFolderTeamA([])
    setFolderTeamB([])
  }

  const handleRaised = () => setRaised(!raised)

  const handleOpen = async (id) => {
    await dispatch(fetchEsportGamesReplay(id)).then(() => {
      setOpen(true)
    })
    props.esportGame && replays.esportGameReplayList.length !== 0 ?
      dispatch(fetchMetaData(props.replay.demo_file_hash,
        replays.esportGameReplayList?.[0].spectated_player_steam_id,
        replays.esportGameReplayList?.[0].recorded_half))
      :
      dispatch(fetchMetaData(props.replay.esport_game.demo_file_hash,
        props.replay.spectated_player_steam_id,
        props.replay.recorded_half))
  }

  let currentTeam = null
  let teamLogoA = ""
  let teamLogoB = ""

  const replayDate = !props.esportGame ? new Date(props.replay.esport_game.date) : new Date(props.replay.date)

  const [folderTeamA, setFolderTeamA] = useState([])
  const [folderTeamB, setFolderTeamB] = useState([])

  useEffect(() => {
    if (replays.esportGameReplayList.length !== 0 && open) {
      currentTeam = props.esportGame ? props.replay.team_a : props.replay.esport_game.team_a
      replays.esportGameReplayList.map((relatedReplay) =>
        checkAliasForTeamRelatedReplay(relatedReplay.spectated_player_team, esportTeams) === checkAliasForTeamRelatedReplay(currentTeam, esportTeams) ?
          folderTeamA.push(relatedReplay) : folderTeamB.push(relatedReplay)
      )
    }
  }, [replays, open])

  return (
    <>
      {props.esportGame ?
        <Grid item md={6} xs={12} lg={3} xl={3} sm={6}>
          <Card onMouseOver={handleRaised} onMouseOut={handleRaised} raised={raised}>
            <CardActionArea onClick={() => handleOpen(props.replay.id)} mb={1}>
              <CardMedia image={matchDialog(props.replay.map)} className={classes.cardGameSizing}>
                <Box pt={3} pb={1} align="center">
                  <Grid container direction="row" justify="center" alignItems="center">
                    {props.logoFolder.allFile.edges.forEach(folder => {
                      if (folder.node.name + folder.node.ext === checkAliasForTeamLogo(props.replay.team_a, esportTeams)) {
                        teamLogoA = folder.node.publicURL
                      }
                      else if (folder.node.name + folder.node.ext === checkAliasForTeamLogo(props.replay.team_b, esportTeams))
                        teamLogoB = folder.node.publicURL
                    })}
                    <Grid item xs={3}>
                      <img src={teamLogoA} className={classes.teamLogoProperty} />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body2" className={classes.colortypoCardTitle}>{props.replay.team_a_score ?? "0"}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <RemoveRoundedIcon fontSize="large" className={classes.childrenAppBar} />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body2" className={classes.colortypoCardTitle}>{props.replay.team_b_score ?? "0"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <img src={teamLogoB} className={classes.teamLogoProperty} />
                    </Grid>
                  </Grid>
                </Box>
                <Box align="center" pb={2} mt={1}>
                  <Grid container direction="row" justify="center" alignItems="flex-end" spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant="caption" className={classes.colortypoCardTitle}>{props.replay.esport_event.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" className={classes.colortypoCardTitle}>{props.replay.map}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" className={classes.colortypoCardTitle}>{MonthNames[replayDate.getMonth()]} {replayDate.getFullYear()}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box align="center">
                  <GridListTileBar
                    title={
                      <>
                        <Grid container spacing={5}>
                          <Grid item xs={5}>
                            <Typography variant="body2" noWrap className={classes.colortypoCardTitleName}>
                              {props.replay.team_a}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            VS
                          </Grid>
                          <Grid item xs={5}>
                            <Typography variant="body2" noWrap className={classes.colortypoCardTitleName}>
                              {props.replay.team_b}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                </Box>
              </CardMedia>
            </CardActionArea>
          </Card>
        </Grid>
        :
        !props.highlightRound ?
          <Grid item md={6} xs={12} lg={3} xl={3} sm={6}>
            <Card onMouseOver={handleRaised} onMouseOut={handleRaised} raised={raised} className={classes.cardSizing}>
              <CardActionArea onClick={() => handleOpen(props.replay.esport_game.id)} mb={1}>
                <CardMedia image={`https://img.youtube.com/vi/${props.replay.youtube_id}/hqdefault.jpg`} className={classes.media} />
                <Box align="center">
                  <GridListTileBar title={<Typography variant="h6" noWrap className={classes.colortypoCardTitleName}>{props.replay.spectated_player_nickname}</Typography>} />
                </Box>
              </CardActionArea>
              <Box pt={2} mt={1} className={props.replay.recorded_side === "CT" ? classes.sideIconCounter : classes.sideIconTerro}>
                <Box mt={5} />
                <Box display="flex" justifyContent="center">
                  <Typography variant="subtitle2" className={classes.colortypoCardTitle}>{props.replay.esport_game.map}</Typography>
                </Box>
                <Box justifyContent="center" clone mt={-1.5}>
                  <CardActions disableSpacing={true} className={classes.cardTitle}>
                    <Typography variant="caption" gutterBottom className={classes.colortypoCardTitle} align="center">{props.replay.kill}<FontAwesomeIcon className={classes.iconProperty} icon={faCrosshairs} /> - {props.replay.death}<FontAwesomeIcon className={classes.iconProperty} icon={faSkullCrossbones} /></Typography>
                  </CardActions>
                </Box>
                <Box>
                  {props.replay.opponent_team && props.replay.spectated_player_team && props.replay.esport_game.esport_event !== undefined ?
                    <>
                      <Box justifyContent="center" clone mt={-1}>
                        <CardActions disableSpacing={true} className={classes.cardTitle}>
                          <Typography variant="caption" noWrap className={classes.colortypoCardTitleName}>{props.replay.spectated_player_team} VS {props.replay.opponent_team}</Typography>
                        </CardActions>
                      </Box>
                      <Box justifyContent="center" clone mt={-2}>
                        <CardActions disableSpacing={true} className={classes.cardTitle}>
                          {props.replay.esport_game.esport_event
                            ? <Typography variant="caption" noWrap gutterBottom className={classes.colortypoCardTitle} align="center">{props.replay.esport_game.esport_event.name}</Typography>
                            : <Box mb={4} />
                          }
                        </CardActions>
                      </Box>
                      <Box justifyContent="center" clone mt={-2.5}>
                        <CardActions disableSpacing={true} className={classes.cardTitle}>
                          <Typography variant="caption" gutterBottom className={classes.colortypoCardTitle} align="center">{MonthNames[replayDate.getMonth()]} {replayDate.getFullYear()}</Typography>
                        </CardActions>
                      </Box>
                    </>
                    :
                    <Box mb={4} clone>
                      <CardActions className={classes.cardTitle} />
                    </Box>
                  }
                </Box>
              </Box>
            </Card>
          </Grid>
          :
          <Grid item md={6} xs={12} lg={3} xl={3} sm={6}>
            <Card onMouseOver={handleRaised} onMouseOut={handleRaised} raised={raised} className={classes.cardSizing} >
              <CardActionArea onClick={() => handleOpen(props.replay.esport_game.id)} mb={1}>
                <CardMedia image={`https://img.youtube.com/vi/${props.replay.youtube_id}/hqdefault.jpg`} className={classes.media} />
                <Box align="center">
                  <GridListTileBar title={<Typography variant="h6" noWrap className={classes.colortypoCardTitleName}>{props.replay.spectated_player_nickname}</Typography>} />
                </Box>
              </CardActionArea>
              <Box mt={1}>
                <Box mt={5} />
                <Box display="flex" justifyContent="center">
                  <Typography variant="subtitle2" className={classes.colortypoCardTitle}>{props.replay.esport_game.map}</Typography>
                </Box>
                {props.replay.opponent_team && props.replay.spectated_player_team && props.replay.esport_game.esport_event !== undefined ?
                  <>
                    <Box justifyContent="center" clone mt={-1}>
                      <CardActions disableSpacing={true} className={classes.cardTitle}>
                        <Typography variant="caption" noWrap className={classes.colortypoCardTitleName}>{props.replay.spectated_player_nickname} VS {props.replay.opponent_team}</Typography>
                      </CardActions>
                    </Box>
                    <Box justifyContent="center" clone mt={-2}>
                      <CardActions disableSpacing={true} className={classes.cardTitle}>
                        {props.replay.esport_game.esport_event
                          ? <Typography variant="caption" noWrap gutterBottom className={classes.colortypoCardTitle} align="center">{props.replay.esport_game.esport_event.name}</Typography>
                          : <Box mb={4} />
                        }
                      </CardActions>
                    </Box>
                    <Box justifyContent="center" clone mt={-2.5}>
                      <CardActions disableSpacing={true} className={classes.cardTitle}>
                        <Typography variant="caption" gutterBottom className={classes.colortypoCardTitle} align="center">{MonthNames[replayDate.getMonth()]} {replayDate.getFullYear()}</Typography>
                      </CardActions>
                    </Box>
                  </>
                  :
                  <Box mb={4} clone>
                    <CardActions className={classes.cardTitle} />
                  </Box>
                }
              </Box>
            </Card>
          </Grid>
      }
      <ReplayDialog open={open} onClose={handleClose} highlightRound={props.highlightRound} youtubePlayerID={props.esportGame ? replays.esportGameReplayList?.[0]?.youtube_id : props.replay.youtube_id} replayDate={replayDate} teamsFolder={esportTeams} replay={props.esportGame ? props.replay : props.replay.esport_game} logoFolder={props.logoFolder} folderTeamA={folderTeamA} folderTeamB={folderTeamB} />
    </>
  )
}

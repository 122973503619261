import * as React from "react"
import { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Pagination from "@material-ui/lab/Pagination"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"

import ReplayItemWrapper from "./ReplayItemWrapper"

const styles = makeStyles(() => ({
  cardTitleRowTypo: {
    color: "#001C28",
    fontWeight: "800",
  },
  cardTitleRow: {
    background: "#1BE483",
  },
}))

export default function ReplayList(props) {
  const classes = styles()

  const [page, setPage] = useState(1)

  const handlePageChange = (e, value) => {
    setPage(value)
  }

  return (
    <>
      <Container maxWidth="lg">
        <Box mt={8} >
          <Grid container justify="flex-start" alignItems="flex-end">
            <Grid item xs={2} align="center">
              <Box position="absolute" zIndex="drawer" mt={-1} ml={-2}>
                <Card className={classes.cardTitleRow}>
                  <Box px={1}>
                    <Typography variant="subtitle2" className={classes.cardTitleRowTypo}>{props.title}</Typography>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={4}>
          {props.list.slice((page - 1) * props.row * 4, page * props.row * 4).map((replay, i) => {
            return <ReplayItemWrapper esportGame={props.esportGame} highlightRound={props.highlightRound} replay={replay} key={i} logoFolder={props.logoFolder} teamsFolder={props.teamsFolder} />
          })}
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Box mt={5} mb={8}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={6}>
              {Math.ceil(props.list.length / props.row / 4) <= 5 ?
                (<Container maxWidth="sm">
                  <Box display="flex" justifyContent="center">
                    {props.list.length > props.row * 4 ?
                      <Pagination page={page} onChange={handlePageChange} count={Math.ceil(props.list.length / props.row / 4)} variant="text" size="large" color="primary" />
                      : null
                    }
                  </Box>
                </Container>)
                :
                (props.list.length > props.row * 4 ?
                  <Pagination page={page} onChange={handlePageChange} count={Math.ceil(props.list.length / props.row / 4)} variant="outlined" size="large" color="primary" />
                  : null
                )
              }
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

import {
  GET_REPLAY_DEFAULT_LIST_SUCCESS,
  GET_REPLAY_LIST_SUCCESS,
  GET_REPLAYS_FAILED,
  SAVE_REPLAYS_SUCCESS,
  SAVE_REPLAYS_FAILED,
} from "../constants/actionTypes"
import { getReplayList, addReplays } from "../api/replays"

export const fetchReplays = (map, team, side, esport_event_id) => async dispatch => {
  try {
    const response = await getReplayList(map, team, side, esport_event_id)
    if (response.data.replays) {
      dispatch({
        type: GET_REPLAY_LIST_SUCCESS,
        payload: { list: response.data.replays.filter(replay => Date.parse(replay.updated_at) < Date.now() - 3600 * 7 * 1000) },
      })
    }
    else {
      dispatch({
        type: GET_REPLAY_LIST_SUCCESS,
        payload: { list: [] },
      })
    }

  } catch (error) {
    dispatch({
      type: GET_REPLAYS_FAILED,
      payload: { error },
    })
  }
}

export const fetchDefaultReplays = () => async dispatch => {
  try {
    const response = await getReplayList()
    if (response.data.replays) {
      const trendingList = response.data.replays.
        filter(replay => Date.parse(replay.updated_at) < Date.now() - 3600 * 7 * 1000).
        filter(replay => Date.parse(replay.esport_game.esport_event.date_end) > Date.now() - 1000 * 3600 * 24 * 10).
        sort((a, b) => (b.kill - b.death) - (a.kill - a.death))

      const latestList = response.data.replays.
        filter(replay => Date.parse(replay.updated_at) < Date.now() - 3600 * 7 * 1000).
        sort((a, b) => (Date.parse(b.esport_game.date) - Date.parse(a.esport_game.date)))

      dispatch({
        type: GET_REPLAY_DEFAULT_LIST_SUCCESS,
        payload: { latestList: latestList, trendingList: trendingList },
      })
    }

  } catch (error) {
    dispatch({
      type: GET_REPLAYS_FAILED,
      payload: { error },
    })
  }
}

export const saveReplaysInDatabase = (selectedReplays) => async dispatch => {
  try {
    let replays = []
    let demoFileHash
    selectedReplays.forEach(replay => {
      replays.push({
        spectated_player_steam_id: replay.playerSteamId,
        spectated_player_nickname: replay.nickName,
        recorded_half: replay.half,
        recorded_side: (replay.half == 1 ? replay.sideHalf1 : replay.sideHalf2),
        custom_comment: replay.customComment,
        spectated_player_team: replay.spectatedPlayerTeam,
        opponent_team: replay.opponentTeam,
      })
      demoFileHash = replay.demoFileHash
    })
    await addReplays(
      demoFileHash,
      replays,
    )
    dispatch({
      type: SAVE_REPLAYS_SUCCESS,
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: SAVE_REPLAYS_FAILED,
    })
  }
}

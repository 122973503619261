import React from "react"
import AppBar from "@material-ui/core/AppBar"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import FavoriteIcon from "@material-ui/icons/Favorite"
import Box from "@material-ui/core/Box"

const styles = makeStyles(() => ({
  appbar: {
    background: "#001C28",
    top: "auto",
    bottom: "0",
  },
  favoriteIcon: {
    color: "red",
    height: "15px",
  },
  footbarText: {
    color: "#FFFFFF",
  },
}))
export default function FootBar() {
  const classes = styles()

  return (
    <AppBar position="fixed" className={classes.appbar}>
      <Box display="flex" justifyContent="center" mt={1} alignItems="center">
        <Box display="flex" justifyContent="center">
          <Typography variant="caption" className={classes.footbarText}>If you think demtovideo is good &nbsp;</Typography>
          <a href="https://twitter.com/demtovideo" data-size="medium" className="twitter-follow-button" data-show-count="false">Follow @demtovideo</a>
        </Box>
        <Box clone>
          <FavoriteIcon className={classes.favoriteIcon} />
        </Box>
      </Box>
    </AppBar>
  )
}

import React from "react"
import { Router, Location } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "@material-ui/core/styles"
import "typeface-montserrat"

import SEO from "../components/seo"
import Dashboard from "../components/dashboard/Dashboard"
import defaultTheme from "../theme/default"

const App = () => {
  const logoFolder = useStaticQuery(graphql`
  query logoFolder {
    allFile(filter: { relativeDirectory: { in: ["event", "team"]}}) {
      edges {
        node {
          name
          ext
          publicURL
          relativeDirectory
        }
      }
    }
  }
  `)

  return (
    <>
      <SEO title="demtovideo" />
      <ThemeProvider theme={defaultTheme}>
        <Location>
          {({ location }) => (
            <Router location={location}>
              <Dashboard path="/app" logoFolder={logoFolder} />
            </Router>
          )}
        </Location>
      </ThemeProvider>
    </>
  )
}

export default App

import { baseApi } from "./index"
import { getParamsCleaner } from "../utils/helpers"

export function getEsportGameList(map, team, esport_event_name) {
  return baseApi.get("/esport-game/list?" + getParamsCleaner("?map=" + map + "&team=" + team + "&esport_event_name=" + esport_event_name))
}

export function getEsportGame(demoFileID, demoFileHash) {
  return baseApi.get(`/esport-game/get?esport_game_id=${demoFileID}&demo_file_hash=${demoFileHash}`)
}

export function getEsportGameReplayList(esport_game_id) {
  return baseApi.get("/esport-game/replay-list?" + getParamsCleaner("?esport_game_id=" + esport_game_id))
}

import React, { useState } from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import {
  IconButton, Box, Typography, Grid,
  Container, Divider, Button, Menu,
  MenuItem,
} from "@material-ui/core"
import YouTube from 'react-youtube';

import flash from "../images/timeIcon/flash.svg"
import he from "../images/timeIcon/he.svg"
import fire from "../images/timeIcon/moly.svg"
import smoke from "../images/timeIcon/smoke.svg"

const useStyles = makeStyles((theme) => ({
  responsiveVideo: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      height: "175px",
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: "100%",
      height: "396px",
    },
    [theme.breakpoints.between(1024, 1366)]: {
      width: "95%",
      height: "513px",
    },
  },
}))

export default function YouTubePlayer(props) {
  const classes = useStyles()
  const [grenadesFilter, setGrenadesFilter] = useState()
  const [lenGrenade, setLenGrenade] = useState()
  const [openMenuGrenades, setOpenMenuGrenades] = useState(null)
  const datas = useSelector(state => state.replayMetaData)

  const opts = {
    width: "90%",
    height: "648px",
    playerVars: {
      iv_load_policy: 3,
      modestbranding: 1,
      showinfo: 0,
    },
  }

  if (props && props.id) {

    const [player, setPlayer] = useState()

    const onReady = (event) => {
      setPlayer(event.target);
      props.setRef(event.target)
    }

    const handleFilters = filter => () => {
      const data = datas.metaDatas.filter((key) =>
        key.Key === filter,
      )
      if (data) {
        player.seekTo(Math.floor(data[0].Key * 0.001 - 2))
      }
    }

    const findGrenades = (value) => {
      switch (value) {
        case 0:
          return flash
        case 1:
          return smoke
        case 2:
          return he
        case 3:
          return fire
      }
    }

    const grenadesNames = (value) => {
      switch (value) {
        case 0:
          return "Flash"
        case 1:
          return "Smoke"
        case 2:
          return "Grenade"
        case 3:
          return "Molotov"
      }
    }


    const countNumberOfEachGrenade = () => {
      let countGrenades = { flash: 0, smoke: 0, he: 0, fire: 0 }
      datas?.metaDatas?.forEach(nade => {
        if (nade.Value === 0)
          countGrenades.flash = countGrenades.flash + 1
        if (nade.Value === 1)
          countGrenades.smoke = countGrenades.smoke + 1
        if (nade.Value === 2)
          countGrenades.he = countGrenades.he + 1
        if (nade.Value === 3)
          countGrenades.fire = countGrenades.fire + 1
      })
      return countGrenades
    }

    const grenadesMapping = [
      { name: "All Utilities", icon: null, number: datas?.metaDatas?.length },
      { name: "Flash", icon: flash, number: countNumberOfEachGrenade().flash },
      { name: "Smoke", icon: smoke, number: countNumberOfEachGrenade().smoke },
      { name: "Grenade", icon: he, number: countNumberOfEachGrenade().he },
      { name: "Molotov", icon: fire, number: countNumberOfEachGrenade().fire }
    ]

    const filterGrenades = (value) => {
      if (grenadesFilter === value)
        return false
      if (grenadesFilter === undefined)
        return false
      if (grenadesFilter === "All Utilities")
        return false
      if (grenadesFilter !== value)
        return true
    }

    const handleClickUtils = (nadeName, nadeLen) => {
      setLenGrenade(nadeLen)
      setGrenadesFilter(nadeName)
      setOpenMenuGrenades(null)
    }

    let nameAndCount = (grenadesFilter && lenGrenade) ? `${grenadesFilter} - ${lenGrenade}` : grenadesFilter

    const handleCategories = () => {
      return (
        <Container maxWidth="lg">
          <Box>
            <Button onClick={(e) => setOpenMenuGrenades(e.currentTarget)}
              style={{ fontWeight: 700, textTransform: "capitalize" }}
            >
              {nameAndCount ?? "All Utilities"}
            </Button>
            <Menu
              anchorEl={openMenuGrenades}
              keepMounted
              open={Boolean(openMenuGrenades)}
              onClose={() => setOpenMenuGrenades(null)}
            >
              {grenadesMapping.map((nade, idx) => (
                <MenuItem
                  key={idx}
                  className={classes.formControl}
                  value={nade.name}
                  name={nade.name}
                  onClick={() => handleClickUtils(nade.name, nade.number)}
                >
                  <Box display="flex">
                    <Typography style={{ width: 100 }} color="primary">{nade.name} - {String(nade.number)}</Typography>
                    <img src={nade.icon} height="20px" style={{ marginLeft: 15 }} />
                  </Box>
                </MenuItem>
              ))}
            </Menu>
            <Grid container>
              {datas.metaDatas?.map((nade) =>
                <Grid item xs={6} sm={3} md={2} lg={2} xl={2} key={nade.Key}>
                  <Box display="flex" alignItems="center" style={{ opacity: filterGrenades(grenadesNames(nade.Value)) ? 0.3 : 1 }}>
                    <Box width={50} clone>
                      <IconButton onClick={handleFilters(nade.Key)} variant="outlined" disabled={filterGrenades(grenadesNames(nade.Value))}>
                        <Typography variant="subtitle2" style={{ color: "#0085FF" }} >{Math.floor((nade.Key * 0.001 - 2) / 60)}.{Math.floor((nade.Key * 0.001 - 2) % 60) < 10 ? "0" + Math.floor((nade.Key * 0.001 - 2) % 60) : Math.floor((nade.Key * 0.001 - 2) % 60)}
                        </Typography>
                      </IconButton>
                    </Box>
                    <Box width={70} clone>
                      <Typography color="primary" align="left">{grenadesNames(nade.Value)}</Typography>
                    </Box>
                    <Box width={20} clone>
                      <img src={findGrenades(nade.Value)} height={20} />
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box >
          <Divider style={{ width: "100%", backgroundColor: "#FFFFFF", marginTop: 15, marginBottom: 15 }} />
        </Container >
      )
    }

    return (
      <Box>
        <YouTube videoId={props.id} opts={opts} onReady={onReady} className={classes.responsiveVideo} />
        {props.highlightRound ? null : handleCategories()}
      </Box>
    )
  }

  return null
}

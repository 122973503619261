import {
  GET_ESPORT_GAME_LIST_SUCCESS,
  GET_ESPORT_GAME_LIST_FAILED,
  GET_ESPORT_GAME_REPLAY_LIST_SUCCESS,
  GET_ESPORT_GAME_REPLAY_LIST_FAILED,
} from "../constants/actionTypes"
import { getEsportGameList, getEsportGameReplayList } from "../api/esportGames"

export const fetchEsportGames = (map, team, esport_event_id) => async dispatch => {
  try {
    const response = await getEsportGameList(map, team, esport_event_id)
    if (response.data.esport_games) {
      dispatch({
        type: GET_ESPORT_GAME_LIST_SUCCESS,
        payload: { esportGameList: response.data.esport_games },
      })
    }
    else {
      dispatch({
        type: GET_ESPORT_GAME_LIST_SUCCESS,
        payload: { esportGameList: [] },
      })
    }

  } catch (error) {
    dispatch({
      type: GET_ESPORT_GAME_LIST_FAILED,
      payload: { error },
    })
  }
}

export const fetchEsportGamesReplay = (esport_game_id) => async dispatch => {
  try {
    const response = await getEsportGameReplayList(esport_game_id)
    if (response.data.replays) {
      dispatch({
        type: GET_ESPORT_GAME_REPLAY_LIST_SUCCESS,
        payload: { esportGameReplayList: response.data.replays },
      })
    }
  }
  catch (error) {
    dispatch({
      type: GET_ESPORT_GAME_REPLAY_LIST_FAILED,
      payload: { error },
    })
  }
}

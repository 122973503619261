import algoliasearch from "algoliasearch/lite"
import {
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  CLEAR_RESULTS,
} from "../constants/actionTypes"

const client = algoliasearch("HTOLRPX9HM", "0f26e0b8ba90c6cd96d5d339048f25a8")
const index = client.initIndex("dev_demtovideo")

export const doSearch = (searchText) => async dispatch => {
  if (searchText !== "") {
    try {
      let resultList = []
      let recordedSide = ""
      const result = await index.search(searchText)
      for (var i = 0; result.hits[i]; i++) {
        if (result.hits[i].replay.recorded_side === 2)
          recordedSide = "T"
        else if (result.hits[i].replay.recorded_side === 3)
          recordedSide = "CT"
        result.hits[i].replay.recorded_side = recordedSide
        resultList.push(result.hits[i].replay)
      }
      dispatch({
        type: SEARCH_SUCCESS,
        payload: { results: resultList },
      })
    } catch (error) {
      dispatch({
        type: SEARCH_FAILED,
        payload: { error },
      })
    }
  }
}

export const clearResults = () => dispatch => {
  dispatch({
    type: CLEAR_RESULTS,
  })
}

import axios from "axios"
import {
  GET_DTV_TWITCH_CHANNEL_STATUS_SUCCESS,
  GET_DTV_TWITCH_CHANNEL_STATUS_FAILED,
} from "../constants/actionTypes"

export const fetchDTVChannelTwitchStatus = () => async dispatch => {
  try {
    const twitchAPI = axios.create({
      baseURL: "https://api.twitch.tv/kraken",
    })
    const response = await twitchAPI.get("/streams/573661936", {
      headers: {
        "Accept": "application/vnd.twitchtv.v5+json",
        "Client-ID": "sjbg9eh8ek8168xx39v4q7hjmwouwz",
      },
    })

    if (response.data) {
      dispatch({
        type: GET_DTV_TWITCH_CHANNEL_STATUS_SUCCESS,
        payload: { stream: response.data.stream },
      })
    }

  } catch (error) {
    dispatch({
      type: GET_DTV_TWITCH_CHANNEL_STATUS_FAILED,
      payload: { error },
    })
  }
}

import {
  GET_ESPORT_EVENT_LIST_SUCCESS,
  GET_ESPORT_EVENT_LIST_FAILED,
} from "../constants/actionTypes"
import { getEsportEventList } from "../api/esportEvents"

export const fetchEsportEvents = () => dispatch => new Promise((resolve, reject) => {
  try {
    getEsportEventList().then((response) => {
      dispatch({
        type: GET_ESPORT_EVENT_LIST_SUCCESS,
        payload: { list: response.data.esport_events },
      })
      resolve()
    })

  } catch (error) {
    dispatch({
      type: GET_ESPORT_EVENT_LIST_FAILED,
      payload: { error },
    })
    reject()
  }
})

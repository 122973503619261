import { baseApi } from "./index"
import { getParamsCleaner } from "../utils/helpers"

export function getReplayList(map, team, side, esport_event_name) {
  return baseApi.get("/replay/list?" + getParamsCleaner("?map=" + map + "&team=" + team + "&side=" + side + "&esport_event_name=" + esport_event_name))
}

export function getReplay() {
  return baseApi.get("/replay/get")
}

export function addReplays(demoFileHash, replays) {
  return baseApi.post("/replay/add", {
    replays: replays,
    demo_file_hash: demoFileHash,
  })
}

import { getMetaData } from "../api/replayMetaData"

export const fetchMetaData = (demo_file_hash, spectated_player_steam_id, recorded_half) => async dispatch => {
  try {
    const response = await getMetaData(demo_file_hash, spectated_player_steam_id, recorded_half)
    dispatch({
      type: "GET_METADATA_SUCCESS",
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: "GET_METADATA_FAILED",
      payload: { error },
    })
  }
}

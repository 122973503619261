import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useDispatch } from "react-redux"
import { Box, Container, Grid, GridList, GridListTile, GridListTileBar, ListSubheader, Typography } from "@material-ui/core"
import { fetchMetaData } from "../../actions/replayMetaData"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons"
import { faSkullCrossbones } from "@fortawesome/free-solid-svg-icons"
import sideCT from "../../images/iconSideCt.png"
import sideT from "../../images/iconSideT.png"

const styles = makeStyles(() => ({
  gridList: {
    width: "100%",
  },
  colortypoCardTitle: {
    color: "#FFFFFF",
    textShadow: "black 1px 1px 2px",
    fontWeight: "600",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  currentReplay: {
    opacity: 0.5,
  },
  colortypoCardTitleName: {
    color: "#1BE483",
    textShadow: "black 1px 1px 2px",
    fontWeight: "600",
    "&:hover": {
      color: "#1BE483",
    },
  },
  iconProperty: {
    color: "#FFFFFF",
    marginLeft: 5,
  },
}))

export default function ReplayRelated(props) {
  const classes = styles()
  const dispatch = useDispatch()

  const handleFunctionOnTileClick = (replay) => {
    setCurrentID(replay.youtube_id)
    dispatch(fetchMetaData(props.replay.demo_file_hash, replay.spectated_player_steam_id, replay.recorded_half))
  }

  const [currentID, setCurrentID] = useState(props.currentID)

  return (
    <Container maxWidth="md">
      <Grid container direction="row" justify="center" spacing={10}>
        {props.folderTeamA.length !== 0 &&
          <Grid item md={6} xs={12} lg={6} xl={6} sm={12} align="center">
            <Box className={classes.root}>
              <GridList cellHeight={180} className={classes.gridList}>
                <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
                  <ListSubheader component="div" className={classes.colortypoCardTitle}>{props.replay.team_a}</ListSubheader>
                </GridListTile>
                {props.folderTeamA.map((replay, id) => {
                  return (
                    <GridListTile style={{ height: "120px", border: "1px groove #FFFFFF", cursor: "pointer" }} key={id} onClick={() => props.youtubePlayerRef.cueVideoById(replay.youtube_id) && handleFunctionOnTileClick(replay)}>
                      <img src={`https://img.youtube.com/vi/${replay.youtube_id}/hqdefault.jpg`} className={currentID === replay.youtube_id ? classes.currentReplay : undefined} />
                      <GridListTileBar
                        title={
                          <Box align="center">
                            <Typography variant="h6" noWrap className={classes.colortypoCardTitleName}>{replay.spectated_player_nickname}{replay.recorded_side === "CT" ? (<img src={sideCT} height="22px" />) : (<img src={sideT} height="22px" />)}</Typography>
                          </Box>
                        }
                        subtitle={
                          <Box align="center">
                            <Typography variant="caption" gutterBottom className={classes.colortypoCardTitle} align="center">{replay.kill}<FontAwesomeIcon className={classes.iconProperty} icon={faCrosshairs} /> - {replay.death}<FontAwesomeIcon className={classes.iconProperty} icon={faSkullCrossbones} /></Typography>
                          </Box>
                        }
                      />
                    </GridListTile>
                  )
                })}
              </GridList>
            </Box>
          </Grid>
        }
        {props.folderTeamB.length !== 0 &&
          <Grid item md={6} xs={12} lg={6} xl={6} sm={12} align="center">
            <GridList className={classes.gridList}>
              <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
                <ListSubheader component="div" className={classes.colortypoCardTitle}>{props.replay.team_b}</ListSubheader>
              </GridListTile>
              {props.folderTeamB.map((replay, id) => {
                return (
                  <GridListTile style={{ height: "120px", border: "1px groove #FFFFFF", cursor: "pointer" }} key={id} onClick={() => props.youtubePlayerRef.cueVideoById(replay.youtube_id) && handleFunctionOnTileClick(replay)}>
                    <img src={`https://img.youtube.com/vi/${replay.youtube_id}/hqdefault.jpg`} className={currentID === replay.youtube_id ? classes.currentReplay : undefined} />
                    <GridListTileBar
                      title={
                        <Box align="center">
                          <Typography variant="h6" noWrap className={classes.colortypoCardTitleName}>{replay.spectated_player_nickname}{replay.recorded_side === "CT" ? (<img src={sideCT} height="22px" />) : (<img src={sideT} height="22px" />)}</Typography>
                        </Box>
                      }
                      subtitle={
                        <Box align="center">
                          <Typography variant="caption" gutterBottom className={classes.colortypoCardTitle} align="center">{replay.kill}<FontAwesomeIcon className={classes.iconProperty} icon={faCrosshairs} /> - {replay.death}<FontAwesomeIcon className={classes.iconProperty} icon={faSkullCrossbones} /></Typography>
                        </Box>
                      }
                    />
                  </GridListTile>
                )
              })}
            </GridList>
          </Grid>
        }
      </Grid>
    </Container>
  )
}

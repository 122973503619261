import matchInferno from "../images/match/matchInferno.png"
import matchD2 from "../images/match/matchD2.png"
import matchMirage from "../images/match/matchMirage.png"
import matchNuke from "../images/match/matchNuke.png"
import matchOverpass from "../images/match/matchOverpass.png"
import matchTrain from "../images/match/matchTrain.png"
import matchVertigo from "../images/match/matchVertigo.png"
import matchAncient from "../images/match/matchAncient.png"

export const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

export const isValidURL = (str) => {
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  if (regexp.test(str))
    return true
  else
    return false
}

export const getParamsCleaner = (params) => {
  let searchParams = new URLSearchParams(params)
  let outSearchParams = new URLSearchParams()
  searchParams.forEach(function (value, key) {
    if (value != "undefined") {
      outSearchParams.append(key, value)
    }
  })
  return outSearchParams.toString()
}

export const matchDialog = (match) => {
  switch (match) {
    case "de_inferno":
      return matchInferno
    case "de_dust2":
      return matchD2
    case "de_mirage":
      return matchMirage
    case "de_nuke":
      return matchNuke
    case "de_overpass":
      return matchOverpass
    case "de_train":
      return matchTrain
    case "de_vertigo":
      return matchVertigo
    case "de_ancient":
      return matchAncient
  }
}

export const checkAliasForTeamRelatedReplay = (teamName, esportTeams) => {
  let splitted = null
  esportTeams.list.forEach(team => {
    if (team.alias_list) {
      splitted = team.alias_list.split(",")
    }
    if (splitted !== null) {
      for (var index = 0; splitted[index]; index++) {
        if (splitted[index] === teamName) {
          teamName = team.name
        }
      }
    }
    else if (team.name === teamName) {
      teamName = team.name
    }
  })
  return teamName
}

export const checkAliasForTeamLogo = (teamName, esportTeams) => {
  teamName = checkAliasForTeamRelatedReplay(teamName, esportTeams)
  esportTeams.list.forEach(team => {
    if (team.alias_list === teamName || team.name === teamName) {
      teamName = team.logo
    }
  })
  return teamName
}

import { baseApi } from "./index"

export const getMetaData = (demo_file_hash, spectated_player_steam_id, recorded_half) => {
  const arr = [demo_file_hash, spectated_player_steam_id, recorded_half]
  return baseApi.get("".concat(
    "/demos/",
    arr.join("."),
    ".json",
  ))
}

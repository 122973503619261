import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import AppBar from "@material-ui/core/AppBar"
import Typography from "@material-ui/core/Typography"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Brightness1Icon from "@material-ui/icons/Brightness1"
import { makeStyles } from "@material-ui/core/styles"
import MuiLink from "@material-ui/core/Link"
import Box from "@material-ui/core/Box"

import LogoDTV from "../images/about/logo-dtv.png"
import twitchLogo from "../images/twitch_logo.svg"
import { fetchDTVChannelTwitchStatus as fetchDTVChannelTwitchStatusAction } from "../actions/twitch"

function ElevationScroll({ children }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })
  return React.cloneElement(children, {
    elevation: trigger ? 20 : 0,
  })
}

const styles = makeStyles((theme) => ({
  linkproperty: {
    color: "#FFFFFF",
    border: "none",
  },
  appbar: {
    background: "#001C28",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  itemMenu: {
    margin: theme.spacing(0),
  },
  colorTypo: {
    color: "#F9F9F3",
    fontWeight: "1000",
    textShadow: "3px 3px #001C28",
    marginTop: theme.spacing(1),
    justifyContent: "center",
  },
  colorTypoAbout: {
    color: "#1BE483",
    fontWeight: "1000",
    textShadow: "3px 3px #001C28",
    marginTop: theme.spacing(1),
    justifyContent: "center",
  },
  onlineTwitchButton: {
    color: "#1BE483",
    borderRadius: "100%",
    boxShadow: "0px 0px 30px #1BE483",
    height: "15px",
  },
  offlineTwitchButton: {
    color: "#e73827",
    border: "0px none #000000",
    borderRadius: "100%",
    boxShadow: "0px 0px 30px #e73827",
    height: "15px",
  },
}))

function Navbar(props) {
  const classes = styles()
  const [anchorEl, setAnchorEl] = useState(false)

  useEffect(() => {
    props.fetchDTVChannelTwitchStatusAction()
    setInterval(() => props.fetchDTVChannelTwitchStatusAction(), 30000)
  }, [])

  const handleClickOpen = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(false)

  return (
    <ElevationScroll>
      <Box position="absolute" zIndex="modal">
        <AppBar className={classes.appbar}>
          <Container maxWidth="md">
            <Grid container direction="row" alignItems="center">
              <Hidden smDown>
                <Grid item sm>
                  <Link to="/app">
                    <img src={LogoDTV} height="20px" alt="Logo" />
                  </Link>
                </Grid>
                <Box mt={1}>
                  <Grid item sm>
                    <Link to="/about">
                      <Typography
                        variant="caption"
                        className={classes.colorTypoAbout}
                      >
                        About
                      </Typography>
                    </Link>
                  </Grid>
                </Box>
                <Grid item xs />
                <Box mt={0.5} mr={8} align="center">
                  <Grid item xs align="center">
                    <Typography variant="body2" className={classes.colorTypo}>
                      Unleash your skills
                    </Typography>
                  </Grid>
                </Box>
                <Grid item xs={4} align="right">
                  <Box
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="end"
                    mt={1}
                  >
                    <MuiLink
                      href="https://www.twitch.tv/demtovideo"
                      target="blank"
                    >
                      <img src={twitchLogo} className="hvr-bob" height="20px" />
                    </MuiLink>
                    <Box ml={1}>
                      {props.twitch.stream != null ? (
                        <Brightness1Icon
                          className={classes.onlineTwitchButton}
                        />
                      ) : (
                        <Brightness1Icon
                          className={classes.offlineTwitchButton}
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item sm>
                  <Link to="/app">
                    <img src={LogoDTV} height="20px" alt="Logo" />
                  </Link>
                </Grid>
                <Grid item xs />
                <Box mt={1} align="center">
                  <Grid item sm align="center">
                    <Typography variant="body2" className={classes.colorTypo}>
                      Unleash your skills
                    </Typography>
                  </Grid>
                </Box>
                <Grid item xs />
                <Grid item xs />
                <Grid item>
                  <IconButton
                    onClick={handleClickOpen}
                    className={classes.linkproperty}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Box position="absolute" zIndex="tooltip">
                    <Menu
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorEl={anchorEl}
                      classes={{ paper: classes.itemMenu }}
                    >
                      <MenuItem
                        to="/about"
                        className={classes.colorTypo}
                        component={Link}
                      >
                        <Typography
                          variant="caption"
                          className={classes.colorTypoAbout}
                        >
                          About
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <MuiLink
                          href="https://www.twitch.tv/demtovideo"
                          target="blank"
                        >
                          <img
                            src={twitchLogo}
                            className="hvr-bob"
                            height="20px"
                          />
                        </MuiLink>
                        <Box ml={1}>
                          {props.twitch.stream != null ? (
                            <Brightness1Icon
                              className={classes.onlineTwitchButton}
                            />
                          ) : (
                            <Brightness1Icon
                              className={classes.offlineTwitchButton}
                            />
                          )}
                        </Box>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Grid>
              </Hidden>
            </Grid>
          </Container>
        </AppBar>
      </Box>
    </ElevationScroll>
  )
}

const mapStateToProps = (state) => ({
  twitch: state.twitch,
})

const mapDispatchToProps = {
  fetchDTVChannelTwitchStatusAction: () => fetchDTVChannelTwitchStatusAction(),
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
